@use "../partials/variables" as *;
@use "./mixins" as *;
@use "../partials/typography" as *;


html {
  scroll-behavior: smooth;
}
body {
  font-family: "SourceSans", sans-serif;
  background-color: $white;
}
h1 {
  font-family: "SourceSans", sans-serif;
  margin-bottom: 1rem;
  font-size: 102px;
  color: $main-blue;
  font-weight: 400;
  @include tablet-portrait {
    font-size: 90px;
  }
  @include mobile {
    font-size: 44px;
  }
}

h2 {
  font-family: "SourceSans", sans-serif;
  font-weight: 500;
  margin: 0;
}
