@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/global" as *;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  border: none;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $white;
  z-index: -1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include mobile-large {
      margin-bottom: 28.125rem;
    }
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;

    &:last-of-type {
      flex-direction: column;
    }
  }
  .hero-subtitle {
    color: $dark-blue;
    font-size: 1.25rem;
  }
  .hero-text {
    font-size: 1.25rem;
    padding: 1rem 0rem;
    margin-bottom: 7.5rem;
    margin-left: 15.625rem;
    @include tablet-portrait {
      padding: 0;
      margin-bottom: 7.5rem;
      margin-left: 0rem;
    }
    @include mobile-large {
      margin-left: 0;
      margin-right: 6.25rem;
      padding-left: 1.875rem;
      margin-bottom: -36.25rem;
    }
  }
  .hero-title {
    font-weight: 500;
  }

  &__boat--img {
    width: 100%;
    @include tablet-portrait {
      display: none;
    }
  }

  &__background {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    contain: content;

    &--img1 {
      align-self: flex-start;
      width: 768px;
      margin-top: 12.5rem;
      margin-left: -3.125rem;
      @include tablet-portrait {
        width: 640px;
      }
      @include mobile-large {
        width: 320px;
        display: none;
      }
    }
    &--img2 {
      align-self: flex-end;
      width: 480px;
      margin-bottom: -25rem;
      margin-top: -0.0625rem;
      @include tablet-portrait {
        align-self: flex-end;
        width: 480px;
        margin-bottom: -25rem;
      }
      @include mobile {
        width: 480px;
      }
    }
  }
}
