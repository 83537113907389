@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/global" as *;

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  width: 90%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-family: "SourceSans";
  background-color: $white;
  border-radius: 1.25rem;
  @include desktop-small{width: 90%;}
  @include mobile-large {
    margin-top: .625rem;
    margin-bottom: 3.125rem;
    width: 90%;
  }

  h1 {
    font-size: 2.1875rem;
    font-weight: 600;
    margin-bottom: .625rem;
  }

  .smaller-text {
    font-size: 1.0625rem;
    color: $main-blue;
    margin-bottom: 1.25rem;
  }

  form {
    width: 100%;

    input,
    textarea {
      width: 100%;
      padding: .625rem;
      margin-bottom: .625rem;
      border: 1px solid $light-green;
      border-radius: 4px;

      &:focus {
        border: 3px solid $light-green;
        outline: none;
      }
    }
}}


  .thank-you-note {
    font-size: 1.5625rem;
    text-align: center;
    color: $light-green;
    display: flex;
    align-items: center;
  }

.form-header {
  color: $light-green;
}
.form-div {
    @include mobile-large {justify-content: center;}
    display: flex;
}

.icon-instagram-form {
    position: relative;
    border-radius: 40%;
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
   background-color: $light-green;
}
.icon-instagram-form:hover {
    visibility: visible;
    pointer-events: auto;
    transform: scale(1.1);
  }

  button {
    padding: .625rem 1.25rem;
    border: none;
    border-radius: .625rem;
    background-color: $light-green;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    transition: border-color 0.3s, transform 0.3s;
  }
    
  button:hover {
    border-color: #fff;
    transform: scale(1.1);
  }

