@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/global" as *;

.entire-services-page {
  background-color: $white;
  position: relative;
}

.services-title {
  font-size: 6.25rem;
  font-family: "SourceSans";
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: $main-blue;
  width: 100%;
  padding-top: 3.125rem;
  @include mobile {
    padding-top: 5rem;
    font-size: 3.125rem;
  }
}
.services-sub-title {
  display: flex;
  justify-content: center;
}
.services-container {
  justify-content: center;
  display: flex;
  color: white;
  align-items: center;
  width: 100%;
  @include mobile {
    margin-top: 1.875rem;
  }
}

ul {
  list-style: none;
}

li {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.top-row-cards {
  display: flex;
  @include tablet-landscape {
    flex-direction: column;
  }
}
.bottom-row-cards {
  display: flex;
  justify-content: center;
  @include tablet-landscape {
    flex-direction: column;
    padding-bottom: 5rem;
  }
}
.card {
  position: relative;
  width: 21.25rem;
  height: 21.25rem;
  margin: .625rem;
  transform-style: preserve-3d;
  transition: all 0.8s linear;
  border-radius: 1.875rem;

  &:hover {
    transform: rotateY(180deg);
    box-shadow: -0.3125rem .3125rem .9375rem -blue;
    cursor: pointer;
  }
  @include tablet-landscape {
    width: 18.75rem;
    height: 15.625rem;
  }
  @include desktop-small {
    width: 20rem;
  }
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 16px;
}

.card-front {
  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card-back {
  display: block;
  transform: rotateY(180deg);
  box-sizing: border-box;
  text-align: center;
  color: $dark-blue;
  border-radius: .625rem;
  padding: 1.25rem;
  padding-top: 6.25rem;
  font-size: 1.5rem;
  @include tablet-landscape {
    padding-top: 0;
  }
}

.corner-image {
  position: absolute;
  height: 30vh;
  @include mobile-large {height: 20vh;}
  @include mobile {
    height: 15vh;
  }
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}
