
@use "./variables" as *;



// Mixin for Mobile
@mixin mobile {
    @media (max-width: $breakpoint-mobile) {
      @content;
    }
  }

  @mixin mobile-large {
    @media (max-width: $breakpoint-mobile-large) {
      @content;
    }
  }
  
  // Mixin for Tablet (Portrait)
  @mixin tablet-portrait {
    @media (max-width: $breakpoint-tablet-portrait) {
      @content;
    }
  }
  
  // Mixin for Tablet (Landscape)
  @mixin tablet-landscape {
    @media (max-width: $breakpoint-tablet-landscape) {
      @content;
    }
  }


  @mixin desktop-small {
    @media (max-width: $breakpoint-desktop-small) {
      @content;
    }
  }