@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/global" as *;

.contact-page {
  background-color: $dark-blue;
  color: $white;
  background-image: url("../../assets/images/leftTree.svg");
  background-repeat: no-repeat;
  background-size: contain;
  font-family: "SourceSans";
  @include desktop-small {
    background-repeat: repeat;
  }
}

.contact-section-header {
  display: flex;
}
.contact-section-text {
  padding: 3.125rem;
  font-weight: 500;
  margin-top: -1.875rem;
  @include mobile {
    padding: 1.25rem;
  }
}
.contact-section {
  font-family: "SourceSans";
  display: flex;
  justify-content: space-evenly;
  @include mobile-large {
    flex-direction: column;
  }
}

.contact-section-title {
  display: flex;
  justify-content: center;
  color: $white;
  font-size: 3.75rem;
  font-weight: 500;
  padding: 1.875rem;
  padding-bottom: 0;
  font-family: "SourceSans";
  @include mobile {
    font-size: 3.5rem;
    padding-bottom: 10px;
  }
}


.location-title {
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
}

// SOCIAL MEDIA ICON

.wrapper {
  display: inline-flex;
  list-style: none;
  align-items: center;
  padding-top: 50px;
}

.wrapper .icon-instagram {
  position: relative;
  border-radius: 40%;
  width: 5.5rem;
  height: 5.5rem;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  @include desktop-small {
    width: 5rem;
    height: 5rem;
  }
  @include mobile {
    width: 4rem;
    height: 4rem;
    margin-left: 3px;
  }
}

.wrapper .icon-instagram:hover {
  visibility: visible;
  pointer-events: auto;
  transform: scale(1.2);
}
.insta-title {
  font-size: 40px;
  white-space: nowrap;
  font-size: 1.5625rem;
}
.icon-phone {
  width: 9.375rem;
  height: 9.375rem;
  margin-left: -3.125rem;
  margin-right: -3.125rem;
}
.phone-number-area {
  display: flex;
  align-items: center;
  margin-top: -3.125rem;
  margin-left: 1.25rem;
  font-size: 1.5625rem;
}
.phone-number {
  text-decoration: none;
  font-size: 1.5625rem;
}

.icon-car {
  width: 9.375rem;
  height: 9.375rem;
  margin-left: -3.125rem;
  margin-right: -2.5rem;
}
.address-area {
  display: flex;
  align-items: center;
  margin-top: -4.375rem;
  margin-left: 1.25rem;
}
.address-text {
  font-size: 25px;
}
.weather-title {
  font-size: 2.5rem;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;

  @include desktop-small {
    flex-direction: column;
    font-size: 2rem;
  }
}
.weather-icon {
  width: 15.625rem;
  height: 15.625rem;
  margin-top: -5rem;
  margin-bottom: -4.0625rem;
  @include desktop-small {
    margin-left: 0px;
    margin-top: -3.125rem;
  }
}

.weather-text-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
