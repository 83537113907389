@font-face {
    font-family: 'SourceSans';
    src: url('../../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
  }
  @font-face {
    font-family: 'SourceSans';
    src: url('../../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
  }
  @font-face {
    font-family: 'SourceSans';
    src: url('../../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: 'SourceSans';
    src: url('../../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'SourceSans';
    src: url('../../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
  }