@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/global" as *;

.cartoon {
  max-width: 100%;
}

.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $dark-blue;
  color: $white;
  background-image: url("../../assets/images/rightTree.svg");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 1.875rem;
  @include desktop-small {
    flex-direction: column;
    background-repeat: repeat;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    }
  }

  .info-container {
    padding-right: 5rem;
    max-width: 43.75rem;
    font-weight: 500;
    @include mobile {
      padding: 1.25rem;
    }

    h2 {
      font-size: 2.5rem;
      margin-bottom: 1.25rem;
      font-weight: 700;
    }

    p {
      line-height: 1.5;
      margin-bottom: 1.25rem;
    }
  }

.about-text {
  font-size: 1.875rem;
  @include mobile-large {
    font-size: 1.25rem;
  }
}
