



$dark-blue: #20448E;
$main-blue: #3F9FC4;
$light-green:#2AB775;
$dark-green: #156064;
$white:#f8fcff;

$breakpoint-mobile-large: 772px;
$breakpoint-mobile: 587px;
$breakpoint-tablet-portrait: 1118px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-desktop-small: 1079px;