@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/global" as *;

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-blue;
  color: $white;
  padding: 4px 20px 4px 20px;
  box-shadow: 0 .125rem .25rem -0.0625rem rgba(0, 0, 0, 0.25);
  @include tablet-portrait {
    padding: 4px 20px 4px 20px;
  }
}

.nav-title {
  font-size: 5rem;
  @include tablet-landscape {font-size: 2.5rem;}
  @include mobile-large {font-size: 1.5625rem;}
  @include mobile {font-size: 1.25rem;}
}
.nav-right {
  display: flex;
  align-items: center;
  z-index: 10;
}

// BUTTON AREA
.nav-contact-button {
  text-decoration: none;
  color: $white;
  font-size: 32px;
  font-weight: 700;
  padding: 10px;
  border-radius: 10px;
  margin-right: 30px;
  background-color: $light-green;
  white-space: nowrap;
  @include desktop-small {margin-right: 0;}
  @include mobile {
    font-size: 16px;
    padding: 4.8px;
    font-weight: 500;
  }

  &:hover {
    border-left: .125rem solid $white;
    border-right: .125rem solid $white;
    border-top: .125rem solid $white;
    border-bottom: .3125rem solid $white;
  }
}

// SOCIAL MEDIA ICONS

.wrapper-nav {
  display: inline-flex;
  list-style: none;
}

.wrapper-nav .icon-instagram-nav {
  position: relative;
  border-radius: 40%;
  margin: .625rem;
  width: 7.5rem;
  height: 7.5rem;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  @include tablet-portrait {width: 6.25rem;
  height: 6.25rem;}
  @include mobile {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.wrapper-nav .icon-instagram-nav:hover  {
  visibility: visible;
  pointer-events: auto;
  transform: scale(1.1);
  
}

